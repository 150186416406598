import { Routes } from "@angular/router";
import { ContactUsComponent } from "./contactUs/contactUs/contactUs.component";
import { HomeComponent } from "./home/home.component";
import { ProjectsComponent } from "./projects/projects.component";
import { AboutUsPageComponent } from "./_pages/aboutUsPage/aboutUsPage.component";
import { ServicesPageComponent } from "./_pages/servicesPage/servicesPage.component";

export const appRoutes: Routes = [
  { path: "home", component:HomeComponent },
  { path: "about", component: AboutUsPageComponent },
  { path: "service", component: ServicesPageComponent },
  { path: "contact", component: ContactUsComponent },
  { path: "projects", component: ProjectsComponent },
  { path: "**", redirectTo: "home", pathMatch: "full" },
];