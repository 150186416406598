import { Component, OnInit } from '@angular/core';
import { GlobalLoaderService } from 'src/app/_services/globalLoader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

  loading: boolean;
  constructor(private globalLoaderService: GlobalLoaderService) {
    this.globalLoaderService.isLoading.subscribe((v) => {
      console.log(v);
      this.loading = v;
    });
   }

  ngOnInit() {
  }

}
