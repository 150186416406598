import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-aboutUsPage',
  templateUrl: './aboutUsPage.component.html',
  styleUrls: ['./aboutUsPage.component.css']
})
export class AboutUsPageComponent implements OnInit {

  constructor(private globalService: GlobalService) { }

  ngOnInit() {
    this.globalService.getHomeData();
  }

}
