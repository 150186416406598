import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../_services/global.service';
declare const Swiper: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private globalService: GlobalService) { }

  ngOnInit() {
    localStorage.clear();
    this.globalService.getHomeData();

    this.swiper();
  }

  swiper(){
    setTimeout(() => {
      var swiper = new Swiper(".mySwiper", {
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 30000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
      });
      var swiper = new Swiper(".project_swiper_container", {
          slidesPerView: 4,
          spaceBetween: 30,
          breakpoints: {
              320: {
                  slidesPerView: 1,
                  spaceBetween: 30,
              },
              640: {
                  slidesPerView: 2,
                  spaceBetween: 30,
              },
              992: {
                  slidesPerView: 4,
                  spaceBetween: 30,
              }
          },
          navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
          },
          autoplay: {
              delay: 30000,
              disableOnInteraction: false,
          },
          pagination: {
              el: ".swiper-pagination",
              clickable: true,
          }
      });

      var swiper = new Swiper(".client_swiper_container", {
          slidesPerView: 5,
          spaceBetween: 30,
          autoplay: {
              delay: 30000,
              disableOnInteraction: false,
          },
          navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
          },
          breakpoints: {
              320: {
                  slidesPerView: 1,
                  spaceBetween: 30,
              },
              640: {
                  slidesPerView: 2,
                  spaceBetween: 30,
              },
              992: {
                  slidesPerView: 3,
                  spaceBetween: 30,
              },
              1024: {
                  slidesPerView: 4,
                  spaceBetween: 30,
              },
              1350: {
                  slidesPerView: 5,
                  spaceBetween: 30,
              }
          },
          pagination: {
              el: ".swiper-pagination",
              clickable: true,
          },
      });
    }, 3000);   
  }

}
