import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'src/app/_services/global.service';
declare const $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  ContactUS_Tel1: any;
  ContactUS_Email: any;
  ContactUS_Address: any;

  constructor(private globalService: GlobalService,public translate: TranslateService) {  
    translate.addLangs(['en', 'ar']);  
    translate.setDefaultLang(this.globalService.lang);  
  } 

  ngOnInit() {
    $("#MasterStyleAr").attr("disabled", "disabled");
    $("#mainStyleAr").attr("disabled", "disabled");

    $(".menu").click(function() {
      $(this).toggleClass('open');
      $('.main-nav-con').slideToggle(500)
    });

    this.switchLang(this.globalService.lang);
  }

  switchLang(lang: string) {  
    localStorage.setItem("lang" , lang);
    this.globalService.lang = lang;
    this.translate.use(lang);  

    if (lang == "ar") {   
      $("#MasterStyle").attr("disabled", "disabled");
      $("#mainStyle").attr("disabled", "disabled");

      $("#MasterStyleAr").removeAttr('disabled');
      $("#mainStyleAr").removeAttr('disabled');          
    }else{
      $("#MasterStyleAr").attr("disabled", "disabled");
      $("#mainStyleAr").attr("disabled", "disabled");

      $("#MasterStyle").removeAttr('disabled');
      $("#mainStyle").removeAttr('disabled');     
    }
  } 

}
