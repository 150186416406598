import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { appRoutes } from "./routes";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header/header.component';
import { SliderComponent } from './slider/slider/slider.component';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './footer/footer/footer.component';
import { AboutUsComponent } from './aboutUs/aboutUs/aboutUs.component';
import { HomeComponent } from './home/home.component';
import { ProjectsComponent } from './projects/projects.component';
import { ContactUsComponent } from './contactUs/contactUs/contactUs.component';
import { AppservicesComponent } from './appservices/appservices.component';
import { NewsComponent } from './news/news.component';
import { ClientsComponent } from './clients/clients.component';
import { LoaderInterceptor } from './_services/loader.interceptor';
import { LoaderComponent } from './loader/loader/loader.component';
import { GlobalLoaderService } from './_services/globalLoader.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';  
import { TranslateHttpLoader } from '@ngx-translate/http-loader';  
import { HttpClient } from '@angular/common/http';  
import { StyleService } from './_services/style.service';
import { AboutUsPageComponent } from './_pages/aboutUsPage/aboutUsPage.component';
import { ServicesPageComponent } from './_pages/servicesPage/servicesPage.component';

@NgModule({
  declarations: [						
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SliderComponent,
    FooterComponent,
    AboutUsComponent,
    ContactUsComponent,
    AboutUsPageComponent,
    ServicesPageComponent,
    ProjectsComponent,
      AppservicesComponent,
      NewsComponent,
      ClientsComponent,
      LoaderComponent
   ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot({  
      loader: {  
         provide: TranslateLoader,  
         useFactory: httpTranslateLoader,  
         deps: [HttpClient]  
         }  
      })  
  ],
  providers: [
    StyleService,
    GlobalLoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function httpTranslateLoader(http: HttpClient) {  
  return new TranslateHttpLoader(http);
}