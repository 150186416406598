import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  baseUrl = environment.apiUrl;
  Sliders: any;
  News: any;
  Services: any;
  ServicesHome: any;
  AboutUs: any;
  AboutUsHomeHead: any;
  AboutUsHomeMain: any;
  AboutUsAbout: any;
  AboutPage: any;
  ContactUs: any;
  Clients: any;
  Projects: any;
  AppServices: any;
  lang = localStorage.getItem("lang") == null ? "en" : localStorage.getItem("lang");

  constructor(private http:HttpClient) { }

  getHomeData() {
    this.http
      .get(this.baseUrl + "getHomeData")
      .subscribe((response: any) => {
        // var result = JSON.parse(response);
        this.Sliders = response.sliders;
        this.AboutUs = response.aboutUs;
        this.AboutUsHomeHead = response.aboutUs.filter(about => about.aboutUS_Category == 'HomeHead');
        this.AboutUsHomeMain = response.aboutUs.filter(about => about.aboutUS_Category == 'HomeMain');
        this.AboutUsAbout = response.aboutUs.filter(about => about.aboutUS_Category == 'About');
        this.AboutPage = response.aboutUs.filter(about => about.aboutUS_Category == 'AboutPage');
        this.Services = response.service;        
        this.ServicesHome = response.service.filter(service => service.service_ID <= 3);    
        this.News = response.news;        
        this.Clients = response.clients;
        this.Projects = response.portfolio;   
        this.ContactUs = response.contactUs;
        
        localStorage.setItem("Companytel", response.contactUs[0].contactUS_Tel1);
        localStorage.setItem("Companyemail",response.contactUs[0].contactUS_Email);
        localStorage.setItem("Companyaddress",response.contactUs[0].contactUS_Address);

      });
  }
}
