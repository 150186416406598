import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-appservices',
  templateUrl: './appservices.component.html',
  styleUrls: ['./appservices.component.css']
})
export class AppservicesComponent implements OnInit {

  constructor(private globalService: GlobalService) { }

  ngOnInit() {
  }

}
